import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import { initDataTable, fillDataTableFilters, calcAlarmDuration, locationAlert, fetchApi } from "../../../Functions.js";
import Filter from '../../../components/Filter';
import LocationAlert from "../../../components/LocationAlert.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
moment.locale('nl');

const ReportHistoryAlarms = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [alarms, setAlarms] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(false);

    const showLoadingScreen = () => {
        setLoadingScreen(true);
    }

    const hideLoadingScreen = () => {
        setLoadingScreen(false);
    }

    const getGraphByDate = async (date) => {
        const graph = await fetchApi("mba", "/mba/report/historyalarm/date", "GET", {
            "reportdate": date
        });

        return graph;
    }

    const getHistoryByDate = async (date) => {
        showLoadingScreen();
        navigate('/reports/historyalarms/' + moment(date).format('YYYY-MM-DD'));
        const data = await fetchApi("mba", "/mba/report/historyalarm", "GET", {
            "reportdate": moment(date).format('YYYY-MM-DD')
        });
        
        await Promise.all(data.map(async (alarm, index, receive) => {
            const teams = await fetchApi("mba", `/mba/report/historyalarm/${alarm.objectid}/teams`, "GET", {
                "alarmid": alarm.objectid
            });
            alarm['teams'] = teams;
        }))

        setAlarms(data);
        initDataTable(); 
        hideLoadingScreen();
    }

    const getData = async (date) => {
        const chart = await import("../../../components/Chart.js");
        getHistoryByDate(date ? date : props.router.params.reportdate);
        const graph = await getGraphByDate(date ? date : props.router.params.reportdate);

        locationAlert();

        const data = Array.from({ length: 24 }, () => [0, 0]);

        const modifiedData = graph.map(alarm => {
            const nn = alarm.minutes === 30 ? 1 : 0;
            return { hour: alarm.hour, nn, nrofalarms: +alarm.nrofalarms };
        });

        modifiedData.forEach(alarm => {
            const { hour, nn, nrofalarms } = alarm;
            data[hour][nn] = nrofalarms;
        });

        const alarmsDayHours = [];
        const alarmsDayCounts = [];

        for (let ii = 0; ii < 24; ii++) {
            alarmsDayHours.push(`${ii}:00`, `${ii}:30`);
            alarmsDayCounts.push(`${data[ii][0]}`, `${data[ii][1]}`);
        }

        const alarmsday = document.getElementById('alarmsDay');
        const init = chart.initLineChart(alarmsday, alarmsDayHours, alarmsDayCounts);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />
            {showLoadingScreen === true && (<div className="loadingScreen"><div className="loader"></div></div>)}

            <div className='loc_content'>
                <h1>{t('historyalarms')}</h1>

                <div className="filters">
                    <Filter type="date" text={t('date')} callback={(data, e) => getData(data)} />
                    <Filter type="select" text={t('type')} id="typeAlarm" column="1" item="reporthistoryalarm-alarmtype" exact={false} />
                    <Filter type="select" text={t('team')} id="teamAlarm" column="2" item="reporthistoryalarm-team" exact={false} />
                    <Filter type="select" text={t('room')} id="roomAlarm" column="0" item="reporthistoryalarm-room" exact={false} />
                    <Export />
                </div>

                <div className="chart">
                    <canvas id="alarmsDay" width="400" height="100"></canvas>
                    <canvas id="NewAlarmsDay" width="400" height="100"></canvas>
                </div>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr>
                                <th>{t('object')}</th>
                                <th>{t('type')}</th>
                                <th>{t('teams')}</th>
                                <th>{t('createdon')}</th>
                                <th>{t('confirmedon')}</th>
                                <th>{t('solvedon')}</th>
                                <th>{t('canceledon')}</th>
                                <th>{t('duration')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alarms.map((alarm, i) => {
                                fillDataTableFilters(document.getElementById('typeAlarm'), alarm.alarmtype);
                                fillDataTableFilters(document.getElementById('roomAlarm'), alarm.object);

                                return (
                                    <tr onClick={() => { navigate(`/reports/historyalarms/alarm/${alarm.objectid}`) }}>
                                        <td>{alarm.object} ({alarm.objectid})</td>
                                        <td>{t(`${alarm.alarmslug}`)}</td>
                                        <td>
                                            {alarm.teams ? alarm.teams.map((team, i) => {
                                                fillDataTableFilters(document.getElementById('teamAlarm'), team.name);
                                                return (<span className="badge-pill badge-info"><div className='alarm-status' style={{ background: team.color }}></div> {team.name}</span>)
                                            }) : ''}
                                        </td>
                                        <td>{alarm.uptriggered ? moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                        <td>{alarm.downtriggered ? moment(alarm.downtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                        <td>{alarm.finishtriggered ? moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                        <td>{alarm.canceltriggered ? moment(alarm.canceltriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                        <td>{calcAlarmDuration(alarm)}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ReportHistoryAlarms);
