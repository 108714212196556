import React, { useState, useEffect } from 'react';
import withRouter from '../../components/withRouter';
import { fetchApi } from "../../Functions.js";
import { useTranslation } from 'react-i18next';

const TeamsSidebar = () => {
    const { t } = useTranslation();
    const [teams, setTeams] = useState([]);
    const [countTeams, setCountTeams] = useState(0);
    const [showSidebar, setShowSidebar] = useState(false); // State voor sidebar zichtbaarheid

    const teamsSwitch = async (event, teamID, teamname) => {
        var btn = event.target;
        teamID = teamID.split('_')[1];
        const type = btn.checked ? 'add' : 'remove';
        await fetchApi("mba", `/team/${type}`, "GET", {
            "teamid": teamID, "teamname": teamname
        });
    }

    const getData = async () => {
        const data = await fetchApi("mba", "/team/get", "GET", {
            "locationid": localStorage.getItem('currentLocation'), "showHidden": false
        });
        var teamcounter = 0;

        data.forEach(team => {
            if (team.invisible === 0) {
                teamcounter++;
            }
        })

        setCountTeams(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') !== 0 ? teamcounter : 0);
        setTeams(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') !== 0 ? data : []);
    }

    useEffect(() => {
        getData();
    }, []);

    // Toggle-functie voor de sidebar
    const toggleSidebar = () => {
        setShowSidebar(prevState => !prevState); // Wissel de zichtbaarheid van de sidebar
        if (!showSidebar) {
                   // Get sidebars
        const teamsSidebar = document.querySelector(".teamsList");
        const alarmsSidebar = document.querySelector(".latest-alarms");
 
        // Hide every sidebar
        teamsSidebar.classList.remove('slide-in');
        alarmsSidebar.classList.remove('slide-in');
 
        // display the right sidebar
        teamsSidebar.classList.add('slide-in');
        }
    };

    return (
        <React.Fragment>
            <span className="header-icon teamsSignUp" onClick={toggleSidebar}> {/* Klikbaar element */}
                <i className="fas fa-users"></i>
                <span className="header-badge badge rounded-pill bg-info">{countTeams}</span>
            </span>
            <div className={`teamsList ${showSidebar ? 'slide-in' : ''}`}> {/* Voorwaardelijke klasse */}
                <div className="closeTeams" onClick={toggleSidebar}> {/* Sluit-icoon */}
                    <i className="fas fa-times"></i>
                </div>

                <h6 className="teamsList_title">
                    <i className="fas fa-users"></i> {t('teams')}
                </h6>

                {!localStorage.getItem("currentLocation") || localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ?
                    <div style={{ display: localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? "none" : "block" }} className="alert alert-warning msg_no_location" role="alert">
                        {t('location_not_selected')}
                    </div> : ''}

                <table>
                    <tbody>
                        {teams.map((team, i) => {
                            var checked = team.current === "ON" ? true : false;

                            return (team.invisible === 0 && team.defaulton === 1 ? 
                                <tr key={i}>
                                    <td style={{ color: '#bbb' }}>{team.name}</td>
                                    <td>
                                        <label className='switch'>
                                            <input id={`switch-${team.id}`} className='togBtn' type='checkbox' defaultChecked={checked} disabled />
                                            <span className='slider round disabled_switch'></span></label>
                                    </td>
                                </tr> : (team.invisible === 0 ?
                                    <tr key={i}>
                                        <td style={{ color: '#333' }}>{team.name}</td>
                                        <td>
                                            <label className='switch'>
                                                <input id={`switch-${team.id}`} onClick={(event) => teamsSwitch(event, team.id, team.name)} className='togBtn' type='checkbox' defaultChecked={checked} />
                                                <span className='slider round disabled_switch'></span></label>
                                        </td>
                                    </tr> : ''));
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TeamsSidebar);
