import React, { useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';
const $ = require('jquery');

const LocationAlert = () => {
	const { t } = useTranslation();

	useEffect(() => {
		const currentLocation = localStorage.getItem('currentLocation');
		const locContent = document.querySelector('.loc_content');

		if(!currentLocation || (currentLocation && currentLocation === '')){
			locContent.style.display = 'none';
	    }else{
			locContent.style.display = 'block';
	    }	
		
	}, [])

	return(
		<div className="alert alert-warning msg_no_location" style={{ display: !localStorage.getItem('currentLocation') || localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') === '' ? 'block' : 'none' }} role="alert">
            {t('location_not_selected')}
        </div>
    )
}

export default withRouter(LocationAlert);