import React, { useEffect, useState } from 'react';
import withAuth from '../../components/Login/withAuth';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const ObjectStatus = () => {
    const [statussen, setStatussen] = useState([]);
    const { t } = useTranslation();

    const downStatus = async (objectstatusid, object, status, device) => {
        const functions = await import("../../Functions");
        const data = await functions.fetchApi("mba", `/mba/object/statusses/${objectstatusid}/down`, "GET", {
            "objectstatusid": objectstatusid, "object": object, "status": status, "device": device
        })

        getData();
        functions.crudAlertControl('update', data);
    }

    const getData = async () => {
        const functions = await import("../../Functions");
        const getStatussen = await functions.fetchApi("mba", "/mba/object/statusses", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        })

        setStatussen(getStatussen);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1>{t('objectstatus')}</h1>

                <div className="alert alert-success alert-update" role="alert">
                    {t('objecthandled')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>
                
                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('objectstatusid')}</th>
                                <th>{t('object')}</th>
                                <th>{t('status')}</th>
                                <th>{t('device')}</th>
                                <th>{t('createdon')}</th>
                                <th>{t('handle')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statussen.map((status, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{status.objectstatusid}</td>
                                        <td>{status.object}({status.objectid})</td>
                                        <td>{status.status}({status.statusid})</td>
                                        <td>{status.device}({status.deviceid})</td>
                                        <td>{status.uptriggered ? moment(status.uptriggered).format('DD-MM-YYYY HH:mm') : ''}</td>
                                        <td><button onClick={() => downStatus(status.objectstatusid, status.object, status.status, status.device)} className='btn-confirm'>{t('handle')}</button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withAuth(ObjectStatus);