import React, { useEffect, useState } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import ModalDelete from "../../components/ModalDelete";
import { confirmDelete, crudAlertControl, initDataTable, fetchApi} from '../../Functions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Tips = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tips, setTips] = useState([]);
    const [modules, setModules] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const add = async (event) => {
        event.preventDefault();

        const data =  await fetchApi("mba", "/mba/tips/insert", "GET", {
          "fronttext": event.target.fronttext.value, "moduleid": event.target.moduleid.value, "backtext": event.target.backtext.value
        })

        if(data && data.length > 0){
          navigate('/manage/tips/' + data[0].id);
        }
    }

    const delTip = async (event, tipid) => {
        const data =  await fetchApi("mba", `/mba/tip/${tipid}/delete`, "GET", {
          "tipid": tipid
        })

        getData();
        crudAlertControl('delete', data);
    }

    const getData = async () => {
        const [getAllTips, getTipsModules] = await Promise.all([
          fetchApi("mba", "/mba/tips", "GET"),
          fetchApi("mba", "/mba/modules/get", "GET")
        ]);

        setTips(getAllTips);
        setModules(getTipsModules);
        initDataTable();
    }

    useEffect(() => {
        getData();
  }, [])

  return (
    <React.Fragment>
      <div className='loc_content'>
        <div onClick={() => setShowModal(true)} className="btn btn-add">{t('add')}</div>
        <h1>Tips</h1>

        <div className="alert alert-success alert-add" role="alert">
          {t('newtip')}
        </div>

        <div className="alert alert-success alert-delete" role="alert">
          {t('deltip')}
        </div>

        <div class="alert alert-danger" role="alert">
          {t('somethingwrong')}
        </div>

        <div className="DataTable_Container">
        <div className="table-responsive">
          <table id="DataTable" class="showHead table responsive nowrap" width="100%">
            <thead>
              <tr class="rowtitle">
                <th>{t('front')}</th>
                <th>{t('module')}</th>
                <th>{t('back')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tips.map((tip, i) => {
                return (
                  <tr key={i}>
                    <td onClick={() => navigate('/manage/tips/' + tip.id)}>{tip.fronttext}</td>
                    <td><i style={{ color: '#00529c', marginRight: '10px' }} className={tip.moduleicon}></i> {t(`${tip.slug}`)}</td>
                    <td>{tip.backtext}</td>
                    <td><i style={{ cursor: 'pointer' }} onClick={(event) => confirmDelete(t('delitem'), function () { delTip(event, tip.id) })} className="btn-delete fas fa-trash-alt"></i></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          </div>
        </div>
      </div>

      <ModalDelete />

      {showModal && (
        <div style={{ display: 'block' }} id="add" class="modal fade in">
        <div class="modal-content ">
          <div class="modal-header">
            <h4 class="modal-title">{t('addtip')}</h4>
            <div onClick={() => setShowModal(false)} class="close">&times;</div>
          </div>

          <div class="modal-body">
            <form onSubmit={(event) => add(event)}>
              <div className="row">
                <div className="col-md-12" style={{ padding: '0' }}>
                  <div class="form-group">
                    <label for="fronttext">{t('front')}</label>
                    <input type="text" name="fronttext" id="fronttext" class="form-control" required />
                  </div>
                </div>
                <div className="col-md-12" style={{ padding: '0' }}>
                  <div class="form-group">
                    <label for="moduleid">{t('module')}</label>
                    <select name="moduleid" id="moduleid" className="form-control">
                      <option value="0">{t('select')}</option>

                      {modules.map((module) => {
                        return (<option value={module.id}>{t(`${module.slug}`)}</option>);
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12" style={{ padding: '0' }}>
                  <div class="form-group">
                    <label for="backtext">{t('back')}</label>
                    <input type="text" name="backtext" id="backtext" class="form-control" required />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6" style={{ padding: '0' }}>
                  <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                </div>
                <div className="col-md-6" style={{ padding: '0' }}>
                  <button type="submit" className="btn btn-green">{t('add')}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      )}
    </React.Fragment>
  );
}

export default withAuth(Tips);