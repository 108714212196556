import React, { useEffect, useState } from 'react';
import withRouter from '../../components/withRouter';
import '../../assets/style.css';
import { useNavigate } from 'react-router';
import { crudAlertControl, fetchApi } from '../../Functions';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const ModuleDetail = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [module, setModule] = useState([]);
    const [roles, setRoles] = useState([]);

    const getData = async () => {
        const [modulesData, categoriesData, rolesData] = await Promise.all([
            fetchApi("mba", `/mba/modules/${props.router.params.id}`, "GET", {
                "moduleid": props.router.params.id
            }),
            fetchApi("mba", "/mba/modules/categories/get", "GET"),
            fetchApi("mba", "/mba/modules/roles/get", "GET")
        ])
        setModule(modulesData[0]);
        setCategories(categoriesData);
        setRoles(rolesData);
    }

    const changeData = async (event) => {
        event.preventDefault();
        const updateData = await fetchApi("mba", `/mba/modules/${props.router.params.id}/update`, "GET", {
            "moduleid": props.router.params.id,
            "moduleicon": event.target.moduleicon.value,
            "moduleurl": event.target.moduleurl.value,
            "modulecategory": event.target.modulecat.value,
            "moduledescription": event.target.moduledesc.value
        })
        crudAlertControl('update', updateData);
        await getData();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0px', color: '#fff' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/modules')}><i class="fas fa-arrow-circle-left"></i></button> {t(`${module.slug ? module.slug : module.name}`)}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updatemodule')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somwthingwrong')}
                    </div>

                    <div class="orgDetails">
                    <div className="table-responsive">
                        <table class="table">
                       <tr className="rowodd">
                        <td className="td">{t('moduleicon')}</td>
                        <td className="td">
                        <input 
                            type="text" 
                            name="moduleicon" 
                            id='moduleicon'
                            value={module.icon} 
                            className="form-control" 
                            required
                            />
                             </td>
                         </tr>
                            <tr class="rowodd">
                                <td class="td">{t('moduleurl')}</td>
                                <td class="td"><input type="text" name="moduleurl" id="moduleurl" defaultValue={module.url} className="form-control" /></td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('category')}</td>
                                <td class="td">
                                    <select defaultValue={module.categorie} name="modulecat" id="modulecat" class="form-control">
                                        <option value="0">{t('select')}</option>
                                            {categories.map((category, i) => {
                                                var selected = module.categorie === category.id ? true : false;
                                                return (<option selected={selected} value={category.id}>{t(`${category.slug}`)}</option>)
                                            })}
                                        </select>
                                </td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('description')}</td>
                                <td class="td"><textarea name="moduledesc" id="moduledesc" defaultValue={module.description} className="form-control" /></td>
                            </tr>
                        </table>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ModuleDetail);