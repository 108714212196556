import React, { useState, useEffect } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const DeviceTypes = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [types, setTypes] = useState([]);

    const getData = async () => {
        const functions = await import("../../Functions");
        const data = await functions.fetchApi("mba", "/mba/devicetype/get", "GET");
        setTypes(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1>{t('devices')}</h1>
                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" class="table responsive nowrap">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('id')}</th>
                                <th>{t('name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {types.map((type, i) => {
                                return (
                                    <tr onClick={() => { navigate(`/manage/devices/object/${type.devicetypeid}`) }}>
                                        <td>{type.devicetypeid}</td>
                                        <td>{type.devicetypename}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(DeviceTypes);