import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import { filterDataTable, fillDataTableFilters } from "../../../Functions.js";
import LocationAlert from "../../../components/LocationAlert.js";
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
import Filter from '../../../components/Filter';
moment.locale('nl');

const Roomunits = () => {
    const [statusses, setStatusses] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", "/mba/sdstatus/get/", "GET");
        setStatusses(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('roomunits')}</h1>
                <div class="filters">
                    <Filter type="select" text={t('type')} id="type" column="2" item="roomunit-type" exact={false} />
                    <Filter type="select" text={t('location')} id="location" column="1" item="roomunit-location" exact={false} />
                    <Export />
                </div>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('device')}</th>
                                <th>{t('location')}</th>
                                <th>{t('type')}</th>
                                <th>{t('lastsignal')}</th>
                                <th>{t('connected')}</th>
                                <th>{t('batlevel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statusses.map((status, i) => {
                                fillDataTableFilters(document.getElementById('type'), status.devicetype);
                                fillDataTableFilters(document.getElementById('location'), status.object);

                                const batStatus = status.batlevel < 33 ? "batRed" : (status.batlevel >= 30 && status.batlevel < 65 ? "batOrange" : "batGreen");
                                const bordercolor = status.batlevel < 33 ? "red" : (status.batlevel >= 30 && status.batlevel < 65 ? "orange" : "green");

                                var plugged = status.pluggedin === 1 ? <i class="check fas fa-check"></i> : <i class="no-check fas fa-times"></i>;

                                return (
                                    <tr>
                                        <td>{status.devicename}</td>
                                        <td>{status.object}</td>
                                        <td>{status.devicetype}</td>
                                        <td>{moment(status.lastheartbeat).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td>{plugged}<span style={{ marginLeft: '10px' }}>{status.pluggedin === 1 ? 'Aangesloten' : 'Niet aangesloten'}</span></td>
                                        <td>
                                            <div className={`battery ${batStatus}`} style={{ border: `solid 2px ${bordercolor}` }}>
                                                <div class="progress" role="progressbar" aria-label="battery" aria-valuenow={Math.floor(status.batlevel)} aria-valuemin="0" aria-valuemax="100" style={{ height: '15px' }}>
                                                    <div class="progress-bar" style={{ width: Math.floor(status.batlevel) + "%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Roomunits);