import React, { useEffect, useState } from 'react';
import '../assets/style.css';
import withAuth from './Login/withAuth';
import TeamsSidebar from './Header/TeamsSidebar';
import AlarmsSidebar from './Header/AlarmsSidebar';
import LoggedInUser from './Header/LoggedInUser';
import AlarmAlert from './Header/AlarmAlert';
import { NavLink } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import {toggleSidebar} from "../Functions.js";

const Header = (props) => {
    const { t } = useTranslation();
    const [userRole, setUserRole] = useState([]);

    const checkRoleSpecifics = async (roleid) => {
        const functions = await import("../Functions.js");
        const role = await functions.fetchApi("mba", `/mba/modules/roles/get/${roleid}`, "GET", {
            "roleid": localStorage.getItem('Role')
        });
       
        return {
          manager: role[0].ismanager === 1 ? true : false,
          admin: role[0].isadmin === 1 ? true : false,
          hasmba: role[0].hasmba === 1 ? true : false
        }
    }

    useEffect(async () => {
        const currentRole = await checkRoleSpecifics(localStorage.getItem('Role'));
        setUserRole(currentRole);
    }, [])

    return (
        <React.Fragment>
            { localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? <AlarmAlert /> : '' }
            <div className="header">
                <div className='mobile_menu' onClick={() => toggleSidebar('open')}><i className='fas fa-bars'></i></div>
                <img alt="logo" className='logo' src="/images/ch-logo-wit.png" />
                <div className="version">{t('version')} 3.7.9</div>

                <div className='header_right' style={{ float: 'right' }}>
                    <LoggedInUser user={props.user} />
                    { userRole.hasmba == true ? <NavLink className="mba-btn" to="/mba-admin/status" exact={true}>MBA</NavLink> : ''}
                    <LanguageSelector />
                    { userRole.admin == true ? <span className="header-icon"><i onClick={() => { window.location.href = '/manage/notifications' }} style={{ color: '#ffffff' }} className='fa fa-cog'></i></span> : ''}
                    <AlarmsSidebar />
                    <TeamsSidebar />
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Header);