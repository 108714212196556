import React, { useEffect, useState } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import { crudAlertControl, fetchApi } from '../../Functions';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const Settings = () => {
    const { t } = useTranslation();
    const [settings, setSettings] = useState([]);
    const [blyottpass, setBlyottpass] = useState([]);

    const securePassword = async (type, password) => {
        type = type === 'encrypt' ? 'encrypt' : 'decrypt';
        const fielddata = await fetchApi("blyott", "/password/" + type, "GET", {
            "password": password
        })
        return fielddata;
    }

    const setField = async (field, value) => {
        const fielddata = await fetchApi("mba", "/mba/settings/update/field", "GET", {
            "fieldid": field, "fieldvalue": value
        })
        crudAlertControl('update', fielddata);
    }

    const updateData = async (event) => {
        event.preventDefault();

        const form = event.target;
        const formData = [];

        Array.from(form.elements).forEach(async (input) => {
            if(input.type === 'submit') return;
            const value = input.type === 'checkbox' ? (input.checked ? '1' : '0') : input.value;
            formData.push({id: input.dataset.id, type: input.dataset.type && input.dataset.type ? input.dataset.type : input.type, value: value})
        })

        formData.forEach(async (field) => {
            if(field.type === 'password'){
                field.value = await securePassword('encrypt', field.value);
            }
            
            await setField(field.id, field.value);
        })
    }

    const getData = async () => {
        const fieldsdata = await fetchApi("mba", "/mba/settings/get", "GET");
        setSettings(fieldsdata);
    };

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <form method="POST" onSubmit={(event)=> updateData(event)}>
                    <input className="btn btn-save" style={{ float: 'right', marginTop: '0px', color: '#fff' }} type="submit" id="submit" name="submit" value={t('save')} />
                    <h1>{t('settings')}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('settingsupdated')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div className="settingsTable" style={{ background: '#ffffff' }}>
                        <div className="table-responsive">
                            <table id="DataTable" className="table responsive nowrap sortTable" width="100%">
                                <thead style={{ display: 'none' }}>
                                    <tr class="rowtitle">
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {settings.map(field => {
                                        if(field.param !== 'tmpww'){
                                            if(field.type === 'bool'){
                                                return(
                                                    <tr>
                                                        <td>{field.param}</td>
                                                        <td>
                                                        <label className='switch'>
                                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} id={field.param} data-id={field.id} defaultChecked={field.value === 1 ? true : false} className={field.param} type='checkbox' />
                                                            <span className='slider round'></span>
                                                        </label>
                                                        </td>
                                                    </tr>
                                                )
                                            }else if(field.type === 'password'){
                                                var value = securePassword('decrypt', field.value ? field.value : field).then(data => {
                                                    setBlyottpass(data);
                                                });
            
                                                return(
                                                    <tr>
                                                        <td>{field.param}</td>
                                                        <td><input key={`${Math.floor((Math.random() * 1000))}-min`} id={field.param} data-id={field.id} data-type="password" className='form-control' defaultValue={blyottpass} type='text' /></td>
                                                    </tr>
                                                )
                                            }else{
                                                return(
                                                    <tr>
                                                        <td>{field.param}</td>
                                                        <td><input key={`${Math.floor((Math.random() * 1000))}-min`} id={field.param} data-id={field.id} className='form-control' defaultValue={field.value} type='text' /></td>
                                                    </tr>
                                                )
                                            }
                                        }
                                        
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default withAuth(Settings);