import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import LocationAlert from "../../../components/LocationAlert.js";
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
moment.locale('nl');

const NeckStatus = () => {
    const [statusses, setStatusses] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", "/mba/neck/get/", "GET");
        setStatusses(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <div className="filters">
                    <Export />
                </div>

                <h2>{t('statustransmitters')}</h2>
                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('device')}</th>
                                <th>{t('location')}</th>
                                <th>{t('lastsignal')}</th>
                                <th>{t('batlevel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statusses.map((status, i) => {
                                var percentage = status.percentage < 0 ? 0 : status.percentage;
                                const batStatus = percentage < 33 ? "batRed" : (percentage >= 30 && percentage < 65 ? "batOrange" : "batGreen");
                                const bordercolor = percentage < 33 ? "red" : (percentage >= 30 && percentage < 65 ? "orange" : "green");

                                return (
                                    <tr>
                                        <td>{status.devicename}</td>
                                        <td>{status.objectname}</td>
                                        <td>{moment(status.lastheartbeat).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        {/* <td><div className='battery'><progress style={{ height: '40px' }} class={`neckbat ${batStatus}`} value={Math.floor(percentage)} max="100"></progress></div></td> */}
                                        <td>
                                            <div className={`battery ${batStatus}`} style={{ border: `solid 2px ${bordercolor}` }}>
                                                <div class="progress" role="progressbar" aria-label="battery" aria-valuenow={Math.floor(percentage)} aria-valuemin="0" aria-valuemax="100" style={{ height: '15px' }}>
                                                    <div class="progress-bar" style={{ width: Math.floor(percentage) + "%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(NeckStatus);