import React, { useEffect, useState } from "react";
import "../../../assets/style.css";
import withRouter from "../../../components/withRouter";
import * as moment from "moment";
import { initDataTable } from "../../../Functions.js";
import { registerLocale } from "react-datepicker";
import DataTable from "datatables.net-dt";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
registerLocale("nl", nl);
moment.locale("nl");

const Receives = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  var currentdate = new Date(props.router.params.reportdate);

  const getReceivesOfDate = async (date, init) => {
    const functions = await import("../../../Functions.js");
    const data = await functions.fetchApi(
      "mba",
      "/mba/report/receives",
      "GET",
      {
        reportdate: date
      }
    );

    await Promise.all(
      data.map(async (object, index, receive) => {
        const teams = await functions.fetchApi(
          "mba",
          "/mba/report/receives/team/",
          "GET",
          {
            objectalarmteamid: object.objectalarmid
          }
        );

        object["teams"] = teams;
        await Promise.all(
          teams.map(async team => {
            const receivedby = await functions.fetchApi(
              "mba",
              "/mba/report/receives/recievedby",
              "GET",
              {
                objectalarmteamid: team.objectalarmteamid
              }
            );
            team["receivedby"] = receivedby;
          })
        );
      })
    );

    setReports(data);
  };

  const goToDate = async date => {
    let table = new DataTable("#DataTable").destroy(); // Vernietig de bestaande DataTable
    navigate("/manage/reports/receives/" + moment(date).format("YYYY-MM-DD"));
    await getReceivesOfDate(moment(date).format("YYYY-MM-DD"));
  };

  useEffect(
    () => {
      getReceivesOfDate(props.router.params.reportdate);
    },
    [props.router.params.reportdate]
  );

  // Gebruik een aparte useEffect om de DataTable te initialiseren nadat de reports zijn bijgewerkt
  useEffect(
    () => {
      if (reports.length > 0) {
        initDataTable(); // Initialiseer de DataTable nadat de nieuwe data is ingesteld
      }
    },
    [reports]
  );

  return (
    <React.Fragment>
      <div className="loc_content">
        <h1>
          {t("receivesreports")}
        </h1>

        <div className="filters">
          {t("date")}:{" "}
          <DatePicker
            locale="nl"
            dateFormat="yyyy-MM-dd"
            selected={currentdate}
            onChange={(data, e) => goToDate(data)}
          />
          <div id="buttons" style={{ float: "right" }} />
        </div>

        <div className="DataTable_Container">
          <div className="table-responsive">
            <table
              id="DataTable"
              className="dataTable-top showHead table responsive nowrap"
              width="100%"
            >
              <thead>
                <tr>
                  <th>
                    {t("object")}
                  </th>
                  <th>
                    {t("date")}
                  </th>
                  <th>
                    {t("team")}
                  </th>
                  <th>
                    {t("receivedby")}
                  </th>
                  <th>
                    {t("receivedon")}
                  </th>
                  <th>
                    {t("duration")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report, index) => {
                  const teammembers = report.teams.flatMap(
                    team => team.receivedby
                  );

                  return (
                    <tr key={index}>
                      <td
                        valign="top"
                        style={{
                          verticalAlign: "middle",
                          paddingTop: "0 !important"
                        }}
                      >
                        {report.name} ({report.objectalarmid})
                      </td>
                      <td
                        valign="top"
                        style={{
                          verticalAlign: "middle",
                          paddingTop: "0 !important"
                        }}
                      >
                        {moment(report.uptriggered).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>
                      <td
                        valign="top"
                        style={{
                          verticalAlign: "middle",
                          paddingTop: "0 !important"
                        }}
                      >
                        {report.teams.map((team, i) => {
                          return (
                            <div key={i}>
                              {team.name}
                            </div>
                          );
                        })}
                      </td>
                      <td
                        valign="top"
                        style={{
                          verticalAlign: "middle",
                          paddingTop: "0 !important"
                        }}
                      >
                        {teammembers.map((member, i) => {
                          return (
                            <div key={i}>
                              {member.name}
                            </div>
                          );
                        })}
                      </td>
                      <td
                        valign="top"
                        style={{
                          verticalAlign: "middle",
                          paddingTop: "0 !important"
                        }}
                      >
                        {teammembers.map((member, i) => {
                          return (
                            <div key={i}>
                              {moment(member.responsedon).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </div>
                          );
                        })}
                      </td>
                      <td
                        valign="top"
                        style={{
                          verticalAlign: "middle",
                          paddingTop: "0 !important"
                        }}
                      >
                        {teammembers.map((member, i) => {
                          var dateUp = moment(report.uptriggered);
                          var dateRes = moment(member.responsedon);
                          var diff = moment
                            .utc(
                              moment(dateRes, "DD/MM/YYYY HH:mm:ss").diff(
                                moment(dateUp, "DD/MM/YYYY HH:mm:ss")
                              )
                            )
                            .format("HH:mm:ss");
                          return (
                            <div key={i}>
                              {diff}
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Receives);
