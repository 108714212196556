import React, { useState, useEffect } from 'react';
import AuthService from './AuthService';
import { useNavigate } from "react-router";

export default function withAuth(AuthComponent) {
    const Auth = new AuthService('http://localhost:8080');
    
    const AuthWrapped = (props) => {
        const [user, setUser] = useState([]);
        const Auth = new AuthService('http://localhost:8080');
        const navigate = useNavigate();

        useEffect(() => {
            if(Auth.loggedIn()){
                try {
                    const profile = Auth.getProfile()
                    setUser(profile);
                }
                catch (err) {
                    Auth.logout()
                    navigate('/login')
                }
            }
        }, [])

        return user ? <AuthComponent history={props.history} user={user} /> : null;
    }
    return AuthWrapped;
}

