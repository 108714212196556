import React, { useEffect, useState } from 'react';
import withAuth from '../../components/Login/withAuth';
import { confirmDelete, crudAlertControl, fetchApi } from "../../Functions.js";
import ModalDelete from "../../components/ModalDelete";
import { useNavigate } from 'react-router';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const Modules = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [modules, setModules] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const getAllModules = async () => {
        const [categoriesData, modulesData] = await Promise.all([
            fetchApi("mba", "/mba/modules/categories/get", "GET"),
            fetchApi("mba", "/mba/modules/get", "GET")
        ])

        setCategories(categoriesData);
        setModules(modulesData);
    }

    const addModule = async (event) => {
        event.preventDefault();
        const moduleData = await fetchApi("mba", "/mba/modules/insert", "GET", {
            "modulename": event.target.modulename.value,
            "moduleicon": event.target.moduleicon.value,
            "moduleurl": event.target.moduleurl.value,
            "modulecategory": event.target.modulecat.value,
            "moduleactive": event.target.moduleactive.checked === true ? 1 : 0
        })

        if(moduleData && moduleData.length > 0){
            navigate('/manage/modules/' + moduleData[0].id);
        }
    }

    const switchModule = async (event, moduleid, modulename) => {
        await fetchApi("mba", `/mba/modules/${moduleid}/switch`, "GET", {
            "moduleid": moduleid, "modulename": modulename, "value": event.target.checked === true ? 1 : 0
        })
    }

    const delModule = async (row, moduleid, modulename) => {
        const deleteData = await fetchApi("mba", `/mba/modules/${moduleid}/delete`, "GET", {
            "moduleid": moduleid, "modulename": modulename
        })

        crudAlertControl('delete', deleteData);
        getAllModules();
    }

    useEffect(() => {
        getAllModules();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <div onClick={() => { navigate("/manage/modules/categories") }} className="btn btn-blue">{t('categories')}</div>
                <div onClick={() => setShowModal(true)} className="btn btn-add">{t('add')}</div>

                <h1>{t('modules')}</h1>
                

                <div className="alert alert-success alert-add" role="alert">
                    {t('newmodule')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('delmodule')}
                </div>

                <div className="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                {categories.map((categorie, i) => {
                    return (
                        <div className="modulesTable">
                            <div className="modulesCategory">{t(`${categorie.slugc ? categorie.slug : categorie.name}`)}</div>
                            <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr className="rowtitle">
                                        <th>{t('name')}</th>
                                        <th>{t('active')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modules.map((module, i) => {
                                        var checked = module.active === 1 ? true : false;

                                        return (module.categorie === categorie.id ? <tr style={{ cursor: 'pointer' }} key={i}>
                                            <td onClick={() => navigate("/manage/modules/" + module.id)}><i style={{ color: '#0085CA', marginRight: '1.125rem' }} className={`icon ${module.icon}`}></i> {t(`${module.slug ? module.slug : module.name}`)}</td>
                                            <td>
                                                <i onClick={(event) => confirmDelete(t('delitem'), function () { delModule(event, module.id, module.name) })} style={{ float: 'right', color: '#ffffff', marginTop: '3px', cursor: 'pointer' }} className="btn-delete fas fa-trash-alt"></i>
                                                <label className='switch' style={{ float: 'right', marginRight: '30px' }}>
                                                    <input key={`${Math.floor((Math.random() * 1000))}-min`} defaultChecked={checked} onChange={(event) => switchModule(event, module.id, module.name)} type='checkbox' />
                                                    <span className='slider round'></span></label>
                                            </td>
                                        </tr> : '');
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>);
                })};
                   
                <ModalDelete />
                
                {showModal && ( 
                    <div style={{ display: 'block' }} id="add" className="modal fade in">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('addmodule')}</h4>
                                <div onClick={() => setShowModal(false)} className="close">&times;</div>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={(event) => addModule(event)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="modulename">{t('modulename')}</label>
                                                <input type="text" name="modulename" id="modulename" className="form-control" required />
                                            </div>
                                            <div className="form-group">
                                                <label for="moduleicon">{t('moduleicon')}</label>
                                                <input
                                                    type="text" 
                                                    name="moduleicon" 
                                                    id='moduleicon'
                                                    value={module.icon} 
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label for="moduleurl">{t('moduleurl')}</label>
                                                <input type="text" name="moduleurl" id="moduleurl" className="form-control" required />
                                            </div>
                                            <div className="form-group">
                                                <label for="modulecat">{t('category')}</label>
                                                <select name="modulecat" id="modulecat" className="form-control" required>
                                                    <option value="">{t('select')}</option>
                                                    {categories.map((category, i) => {
                                                        return (<option value={category.id}>{category.name}</option>)
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label for="moduleactive">{t('active')}</label>
                                                <label className='switch' style={{ float: 'right', marginRight: '30px' }}>
                                                    <input id="moduleactive" name="moduleactive" key={`${Math.floor((Math.random() * 1000))}-min`} type='checkbox' />
                                                    <span className='slider round'></span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-green">{t('add')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default withAuth(Modules);