import React, { useEffect, useState } from 'react';
import Menu from '../../components/MbaMenu';
import ModalDelete from "../../components/MbaModalDelete";
import { checkMBAUserPermission, confirmDelete, crudAlertControl } from './Functions';
import withRouter from '../../components/withRouter';
import { fetchApi } from "../../Functions";

const TimeTablesDetail = (props) => {
    const [timetable, setTimetable] = useState([]);
    const [tablerows, setTablerows] = useState([]);
    const [hours, setHours] = useState([]);
    const [minutes, setMinutes] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const getData = async() => {
        const [data, rowdata] = await Promise.all([
            fetchApi("mba", `/mba/admin/timetable/${props.router.params.id}`, "GET", { "timetableid": props.router.params.id }),
            fetchApi("mba", `/mba/admin/timetable/${props.router.params.id}/rows`, "GET", { "timetableid": props.router.params.id })
        ]);
    
        setTimetable(data[0]);
        setTablerows(rowdata);
    
        setHours([...Array(24).keys()]);
        setMinutes(Array.from({ length: 12 }, (_, i) => i * 5));
    }

    const delRow = async (event, id) => {
        event.preventDefault();
        const data = await fetchApi("mba", `/mba/admin/timetable/${props.router.params.id}/row/delete`, "GET", { 
            "rowid": props.router.params.id 
        });
        crudAlertControl('delete', data);
        await getData();
    }

    const addRow = async (event) => {
        event.preventDefault();
        var timetableid = props.router.params.id;
        var day = event.target.day.value;
        var starthour = event.target.starthour.value;
        var startminute = event.target.startminute.value;
        var endhour = event.target.endhour.value;
        var endminute = event.target.endminute.value;

        const data = await fetchApi("mba", `/mba/admin/timetable/${props.router.params.id}/row/insert`, "GET", { 
            "timetableid": timetableid, "day": day, "starthour": starthour, "startminute": startminute, "endhour": endhour, "endminute": endminute
        });
        setShowModal(false);
        crudAlertControl('add', data);
        await getData();
    }

    const changeTable = async (event) => {
        event.preventDefault();
        const timetablename = event.target.tablename.value;
        const active = event.target.active.checked ? 1 : 0;
        const data = await fetchApi("mba", `/mba/admin/timetable/${props.router.params.id}/update`, "GET", { 
            "timetableid": props.router.params.id, "timetablename": timetablename, "active": active
        });

        var nrofrows = document.getElementsByClassName('tablerow').length;
        var row = document.getElementsByClassName('tablerow');
        var day = document.getElementsByClassName('day');
        var starthour = document.getElementsByClassName('starthour');
        var startminute = document.getElementsByClassName('startminute');
        var endhour = document.getElementsByClassName('endhour');
        var endminute = document.getElementsByClassName('endminute');

        for(var i = 0; i < nrofrows; i++){
            await fetchApi("mba", `/mba/admin/timetable/${props.router.params.id}/row/update`, "GET", { 
                "rowid": row[i].dataset.id, "timetableid": props.router.params.id, "day": day[i].value, "starthour": starthour[i].value, "startminute": startminute[i].value, "endhour": endhour[i].value, "endminute": endminute[i].value
            });
        }

        crudAlertControl('update', data);
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
            <React.Fragment>
                <Menu />
                <div className="alert alert-success alert-update" role="alert">
                    Timetable gewijzigd.
                </div>

                <div className="alert alert-success alert-add" role="alert">
                    Tijd van timetable aangemaakt.
                </div>
                
                <div className="alert alert-success alert-delete" role="alert">
                    Tijd van timetable verwijderd.
                </div>

                <div className="alert alert-danger" role="alert">
                    Er is iets niet goed gegaan. Probeer het later nog eens.
                </div>

                <form onSubmit={(event) => changeTable(event)}>
			        <input style={{marginTop: '0px', marginBottom: '20px'}} type="submit" name="submit" value="Opslaan" className='btn btn-save' />
                    <h2>Wijzig Timetable</h2>

                    <div className="teamDetails">
                        <table className="table responsive nowrap" width="100%">
                            <tr class="roweven">
                                <td class="td">Naam</td>
                                <td class="td"><input style={{ borderBottom: 'solid 1px #ced4da' }} type="text" name="tablename" id="tablename" defaultValue={timetable.name} className="form-control" /></td>
                            </tr>
                            <tr>
                                <td>Actief</td>
                                <td>
                                    <label className='switch'>
                                        <input defaultChecked={timetable.active === 1 ? "true" : ""} key={`${Math.floor((Math.random() * 1000))}-min`} name="active" id="active" type='checkbox' />
                                        <span className='slider round'></span>
                                    </label>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div id="newItem" style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)} class="navbutton">Tijd toevoegen</div>
                    <h2>Tijden</h2>

                    <div className="teamDetails">
                        <table className="table responsive nowrap" width="100%">
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: 'none' }}>Dag</th>
                                    <th style={{ borderBottom: 'none' }}>Van</th>
                                    <th style={{ borderBottom: 'none' }}>Tot</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tablerows.map((row, i) => {
                                    return(
                                        <tr data-id={row.id} className="tablerow" key={`row_${i}`}>
                                            <td style={{ borderBottom: 'none' }}>
                                                <select name="day" className="day form-control" id={`day_${i}`} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={row.day}>
                                                    <option value="0">Selecteer dag</option>
                                                    <option value="2">Maandag</option>
                                                    <option value="3">Dinsdag</option>
                                                    <option value="4">Woensdag</option>
                                                    <option value="5">Donderdag</option>
                                                    <option value="6">Vrijdag</option>
                                                    <option value="7">Zaterdag</option>
                                                    <option value="1">Zondag</option>
                                                </select>
                                            </td>
                                            <td style={{ borderBottom: 'none' }}>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="starthour" className="starthour form-control" id={`starthour_${i}`} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={row.starthour}>
                                                    <option>Selecteer uur</option>
                                                    {hours.map(hour => {
                                                        var hourText = hour < 10 ? "0" + hour : hour;
                                                        
                                                        return(
                                                            <option value={hour}>{hourText}</option>
                                                        )
                                                    })}
                                                </select>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="startminute" className="startminute form-control" id={`startminute_${i}`} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={row.startminute}>
                                                    <option>Selecteer minuten</option>
                                                    {minutes.map(minute => {
                                                        var minuteText = minute < 10 ? "0" + minute : minute;

                                                        return(
                                                            <option value={minute}>{minuteText}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                            <td style={{ borderBottom: 'none' }}>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="endhour" className="endhour form-control" id={`endhour_${i}`} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={row.endhour}>
                                                    <option>Selecteer uur</option>
                                                    {hours.map(hour => {
                                                        var hourText = hour < 10 ? "0" + hour : hour;

                                                        return(
                                                            <option value={hour}>{hourText}</option>
                                                        )
                                                    })}
                                                </select>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="endminute" className="endminute form-control" id={`endminute_${i}`} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={row.endminute}>
                                                    <option>Selecteer minuten</option>
                                                    {minutes.map(minute => {
                                                        var minuteText = minute < 10 ? "0" + minute : minute;

                                                        return(
                                                            <option value={minute}>{minuteText}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                            <td style={{ borderBottom: 'none' }}>
                                                <i onClick={(event) => confirmDelete(function () { delRow(event, row.id) })} style={{ color: '#ff0000', marginTop: '3px', cursor: 'pointer' }} class="fas fa-trash-alt"></i>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </form>

                <ModalDelete />

                {showModal && (
                    <div style={{ display: 'block' }} id="add" className="modal fade in">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">Tijd toevoegen</h4>
                                <div onClick={() => setShowModal(false)} className="close">&times;</div>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={(event) => addRow(event)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="username">Dag</label>
                                                <select name="day" className="form-control" id="day" key={`${Math.floor((Math.random() * 1000))}-min`}>
                                                    <option value="0">Selecteer dag</option>
                                                    <option value="2">Maandag</option>
                                                    <option value="3">Dinsdag</option>
                                                    <option value="4">Woensdag</option>
                                                    <option value="5">Donderdag</option>
                                                    <option value="6">Vrijdag</option>
                                                    <option value="7">Zaterdag</option>
                                                    <option value="1">Zondag</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label style={{ width: '100%' }}>Van</label>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="starthour" className="form-control" id="starthour" key={`${Math.floor((Math.random() * 1000))}-min`}>
                                                    <option>Selecteer uur</option>
                                                    {hours.map(hour => {
                                                        var hourText = hour < 10 ? "0" + hour : hour;
                                                        
                                                        return(
                                                            <option value={hour}>{hourText}</option>
                                                        )
                                                    })}
                                                </select>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="startminute" className="form-control" id="startminute" key={`${Math.floor((Math.random() * 1000))}-min`}>
                                                    <option>Selecteer minuten</option>
                                                    {minutes.map(minute => {
                                                        var minuteText = minute < 10 ? "0" + minute : minute;

                                                        return(
                                                            <option value={minute}>{minuteText}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label style={{ width: '100%' }}>Tot</label>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="endhour" className="form-control" id="endhour" key={`${Math.floor((Math.random() * 1000))}-min`}>
                                                    <option>Selecteer uur</option>
                                                    {hours.map(hour => {
                                                        var hourText = hour < 10 ? "0" + hour : hour;
                                                        
                                                        return(
                                                            <option value={hour}>{hourText}</option>
                                                        )
                                                    })}
                                                </select>
                                                <select style={{ width: '50%', display: 'inline-block' }} name="endminute" className="form-control" id="endminute" key={`${Math.floor((Math.random() * 1000))}-min`}>
                                                    <option>Selecteer minuten</option>
                                                    {minutes.map(minute => {
                                                        var minuteText = minute < 10 ? "0" + minute : minute;

                                                        return(
                                                            <option value={minute}>{minuteText}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">Annuleren</div>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-green">Toevoegen</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}

                
            </React.Fragment>
        )
}

export default withRouter(TimeTablesDetail);