import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withAuth from '../../../components/Login/withAuth';
import LocationAlert from "../../../components/LocationAlert.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Employees = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);

    const getData = async () => {
        const functions = await import("../../../Functions.js");

        const data = await functions.fetchApi("mba", "/mba/team/get/", "GET", {
            "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : '',
            "showHidden": true
        })

        setTeams(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('employeesperteam')}</h1>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="table responsive nowrap">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('team')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams.map((team, i) => {
                                return (
                                    <tr onClick={() => { navigate(`/employees/team/${team.teamid}`) }}>
                                        <td style={{ paddingLeft: "15px" }}>{team.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Employees);
