import React, { useEffect, useState } from 'react';
import '../../assets/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as moment from 'moment';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import withRouter from '../../components/withRouter';
import LocationAlert from '../../components/LocationAlert';
import TipsBlock from './TipsBlock';
import { fetchApi } from "../../Functions.js";
import { useTranslation } from 'react-i18next';
import VirtualRoundCard from '../VirtualRoundCard.js';
const $ = require('jquery');
moment.locale('nl');

const DashboardCustom = (props) => {
    const {t} = useTranslation();
    const [tabscards, setTabscards] = useState([]);
    const [tabindex, setTabindex] = useState(0);
    const [currenttab, setCurrenttab] = useState(0);
    const [showMenu, setShowMenu] = useState(false);

    const changeVolume = async (event) => {
        const data = await fetchApi("mba", "/settings/soundlevel/", "GET" , {
            "objectid": event.target.dataset.object, "soundlevel": event.target.value
        })
    }

    const roomAlarm = async (event) => {
        const type = event.target.dataset.value === 'Enable' ? "alarmenable" : "alarmdisable";
        const data = await fetchApi("mba", `/mba/${type}/${event.target.dataset.id}`, "GET" , {
            "alarmid": event.target.dataset.id,
            "alarmname": event.target.dataset.message
        })

        $(event.target).data('value', event.target.dataset.value === 'Enable' ? 'Disable' : 'Enabled');
        $(event.target).attr('class', event.target.dataset.value === 'Enable' ? 'alarm-icon alarmon' : 'alarm-icon alarmoff');
        return data;
    }

    const fetchData = async (filter) => {
        const chart = await import("../Chart.js");
        const type = filter === 'week' ? '7days' : '24hours' ;
        const alarms = await fetchApi("mba", `/mba/stats/${type}`, "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });

        switch (filter) {
            case 'week':
                chart.getAlarms7Days(alarms);
                chart.getAlarmsTop10_7Days(t);
                chart.getRoomsTop10_7Days(t);
                break;
            default:
                chart.getAlarms24Hours(alarms);
                chart.getAlarmsTop10_24Hours(t);
                chart.getRoomsTop10_24Hours(t);
                break;
        }

        chart.getTeams();
    }

    const getAllTabsWithCards = async () => {
        const functions = await import("../../Functions.js");
        const tabs = await functions.fetchApi("mba", "/mba/cards/tabs", "GET");
        
        await Promise.all(tabs.map(async (tab, index, receive) => {
            const cards = await fetchApi("mba", `/mba/cards/tabs/${tab.id}`, "GET", {
                "tabid": tab.id
            });
            tab['cards'] = cards;

            await Promise.all(cards.map(async (card, index, receive) => {
                const params = await fetchApi("mba", `/mba/cards/${card.id}/parameters`, "GET", {
                    "cardid": card.id
                });
                card['parameters'] = params;

                await Promise.all(params.map(async (param, index, receive) => {
                    if(param.parameter === 'employeeid'){
                        const employee = await fetchApi("mba", `/mba/phonebook/get/${param.value}`, "GET", {
                            "contactid": param.value, "locationid": localStorage.getItem('currentLocation')
                        });

                        if(employee && employee.length > 0){
                            card['contact'] = employee[0];  
                            const device = await fetchApi("mba", `/mba/phonebook/device/${employee[0].deviceid}`, "GET", {
                                "deviceid": employee[0].deviceid
                            });
                            employee[0]['devicedata'] = device;
                        }
                    }

                    if(param.parameter === 'roomid'){
                        const room = await fetchApi("mba", `/settings/getrooms/${param.value}`, "GET", {
                            "roomid": param.value
                        });
                        card['alarmsettings'] = room;
                    }
                }))
            }))
        }))

        setTabscards(tabs);
        setCurrenttab(tabs && tabs.length > 0 ? tabs[0].id : 0);
    }

    const setAlarm = (event) => {
        var messageid = event.target.dataset.message;
        var object = event.target.dataset.object;
        var value = event.target.dataset.value;

        roomAlarm(object, messageid, value);

        if (event.target.dataset.value === 'Enable') {
            event.target.dataset.value = 'Disable';
            event.target.className = 'alarm-icon alarmon';
        } else {
            event.target.dataset.value = 'Enable';
            event.target.className = 'alarm-icon alarmoff';
        }
    }

    fetchData(props.router.location.hash ? props.router.location.hash.split("#")[1] : 'day');

    const filterData = (period) => {
        fetchData(period);
        setShowMenu(false);
    }

    useEffect(() => {
        getAllTabsWithCards();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <i className="dash_view fas fa-ellipsis-v" onClick={() => setShowMenu(true)}></i>

                {showMenu && (
                    <div className="dash_view_options">
                        <span>{t('graphs')}</span>
                        <ul className="alarms_filters">
                            <Link to='/#day' style={{ color: '#000000', textDecoration: 'none' }}><li onClick={() => filterData('day')} className={`btn btn-alarms-filter btn-24hours ${props.router.location.hash.split("#")[1] === 'day' ? 'current' : ''}`}>{t('24hours')}</li></Link>
                            <Link to='/#week' style={{ color: '#000000', textDecoration: 'none' }}><li onClick={() => filterData('week')} className={`btn btn-alarms-filter btn-7days ${props.router.location.hash.split("#")[1] === 'week' ? 'current' : ''}`}>{t('7days')}</li></Link>
                        </ul>

                        <span>{t('settings')}</span>
                        <ul>
                            <li><Link to='/editor' style={{ color: '#000000', textDecoration: 'none' }}>{t('editdashboard')}</Link></li>
                        </ul>
                    </div>
                )}
 
                <div className="tabs dashboard ">
                <h1>Dashboard</h1>
                    <Tabs selectedIndex={tabindex} onSelect={index => {
                        setTabindex(index);
                        setCurrenttab(tabscards[index].id);
                    }}>
                    <TabList>
                        {tabscards.map((tab, i) => {
                            return (
                                <Tab data-id={tab.id}>
                                    {tab.tabname}
                                </Tab>
                            )
                        })}
                    </TabList>

                    {tabscards.map((tab, i) => {
                        return (
                        <TabPanel>
                            <div className="container-fluid dashboard">
                            <div className="grid row">

                                <div className="grid-sizer col-xs-6"></div>
                                    {tab.cards.map((card, i) => {
                                        var alarmClass = '';

                                        var status = {
                                            alarmred: 0,
                                            alarmorange: 0,
                                            status: t('inbathroom'),
                                            border: 'solid 5px rgb(92, 184, 92)',
                                            time: '00 ' + t('hours') + ' 01 ' + t('minutes'),
                                            color: '#FF0000',
                                            icon: 'inbathroom.png',
                                            warning: t('lastcriticalincident') + ': fald - 2023-11-03 14:00',
                                            warningicon: 'fas fa-exclamation-triangle'
                                        }

                                        return (
                                            <div key={i} data-cardid={card.id} className={`col-md-${card.cardsize}`}>
                                                <div className="grid-item-content">
                                                    {card.parameters[0].parameter === 'roomid' ? 
                                                        <VirtualRoundCard roomid={card.parameters[0].value} /> : 
                                                        
                                                        <div className="card" style={{ borderLeft: card.parameters[0].parameter === 'roomid' ? status.border : '' }}>
                                                            <h2 style={{ display: (card.cardname === 'Tips' ? 'none' : 'block') }} className={alarmClass}>{card.cardname}</h2>

                                                            <div className="card-body">
                                                                {card.parameters.map((param, i) => {
                                                                    var AlarmsArray = [];

                                                                    if (param.parameter === 'overviewid' && param.value === "1") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas className="line-graph" id="alarms" width="400" height="200"></canvas>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'overviewid' && param.value === "2") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas className="line-graph" id="alarmstop" width="400"></canvas>
                                                                                <div id="legend" className="legend-day"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'overviewid' && param.value === "3") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas className="line-graph" id="rooms" width="400" height="200"></canvas>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'overviewid' && param.value === "4") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas id="team" width="400"></canvas>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'tips' && param.value === "5") {
                                                                        return (
                                                                            <TipsBlock />
                                                                        );
                                                                    }

                                                                    else if (param.parameter === 'employeeid') {
                                                                        return (
                                                                            <div className='contact'>
                                                                                <i className="fas fa-user-circle"></i>
                                                                                <strong>{t('name')}:</strong> {card.contact ? card.contact.user : ''} <br />
                                                                                <strong>{t('device')}:</strong> {card.contact ? card.contact.device : ''} <br />
                                                                                <strong>{t('devicenumber')}:</strong> {card.contact ? card.contact.devicedata[0].value : ''}
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    }

                                                    
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </TabPanel>)
                    })}
                </Tabs>
            </div>
        </div>
    </React.Fragment>
  );
}

export default withRouter(DashboardCustom);