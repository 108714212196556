import React, { useEffect, useState } from "react";
import withAuth from '../../components/Login/withAuth';
import Menu from '../../components/MbaMenu';
import ModalDelete from "../../components/MbaModalDelete";
import { checkMBAUserPermission, initDataTable, confirmDelete, crudAlertControl } from './Functions';
import { useNavigate } from 'react-router';
import { fetchApi } from "../../Functions";

const TimeTables = () => {
    const navigate = useNavigate();
    const [timetables, setTimetables] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const getData = async() => {
        const data = await fetchApi("mba", "/mba/admin/timetable/get", "GET");
        setTimetables(data);
        initDataTable();
    }

    const addTable = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", "/mba/admin/timetable/insert", "GET", {
            "timetablename": event.target.name.value
        });
        setShowModal(false);
        crudAlertControl('add', data);
        getData();
    }

    const delTable = async (id) => {
        const data = await fetchApi("mba", `/mba/admin/timetable/${id}/delete`, "GET", {
            "timetableid": id
        });
        crudAlertControl('delete', data);
        getData();
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
            <React.Fragment>
                <Menu />
                <h2>Timetables</h2>
                <div onClick={() => setShowModal(true)} className="btn btn-add">Toevoegen</div>

                <div className="alert alert-success alert-add" role="alert">
                    Nieuwe timetable aangemaakt.
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    timetable verwijderd.
                </div>

                <div className="alert alert-danger" role="alert">
                    Er is iets niet goed gegaan. Probeer het later nog eens.
                </div>

                <div className="DataTable_Container">
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {timetables.map((timetable, i) => {

                                return (
                                <tr key={i}>
                                    <td onClick={() => navigate('/mba-admin/timetables/' + timetable.id)}>{timetable.name}</td>
                                    <td>
                                        <i onClick={(event) => confirmDelete(function () { delTable(timetable.id) })} style={{ color: '#ff0000', marginTop: '3px', cursor: 'pointer' }} class="fas fa-trash-alt"></i>
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>

                <ModalDelete />

                {showModal && (
                    <div style={{ display: 'block' }} id="add" className="modal fade in">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">Timetable toevoegen</h4>
                                <div onClick={() => setShowModal(false)} className="close">&times;</div>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={(event) => addTable(event)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="name">Naam</label>
                                                <input type="text" name="name" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">Annuleren</div>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-green">Toevoegen</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
}

export default withAuth(TimeTables);