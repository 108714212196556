import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import LocationAlert from "../../../components/LocationAlert.js";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
registerLocale('nl', nl);
moment.locale('nl');

const EmployeeTeams = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);

	const getData = async () => {
		const functions = await import("../../../Functions.js");

		const teamsHistory = await functions.fetchApi("mba", `/mba/report/employee/${props.router.params.employeeid}/teams/history`, "GET", {
			"employeeid": props.router.params.employeeid,
			"reportdate": props.router.params.reportdate,
		})
		
		setHistory(teamsHistory);
	}

	useEffect(() => {
		getData();
	}, [])

    return (
        <React.Fragment>
            <LocationAlert />
            <div className='loc_content'>
                <h1>Teams history</h1>

				<div className="DataTable_Container">
                <div className="table-responsive">
				<table id="DataTable" className="showHead table responsive" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('team')}</th>
                                <th>{t('loggedin')}</th>
								<th>{t('type')}</th>
                                <th>{t('loggedout')}</th>
                            </tr>
                        </thead>
                        <tbody>
						{history ? history.map((team, i) => {
							return (
								<tr>
									<td>{team.teamname}</td>
									<td>{team.dateadded && team.dateadded != 'undefined' ? moment(team.dateadded).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
									<td>{team.type}</td>
									<td>{team.dateremoved && team.dateremoved != 'undefined' ? moment(team.dateremoved).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
								</tr>)
						}) : ''}
						</tbody>
				</table>
				</div>
				</div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(EmployeeTeams);