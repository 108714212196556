import React, { useEffect, useState } from 'react';
import withAuth from '../../components/Login/withAuth';
import { confirmDelete, crudAlertControl, initDataTable, fetchApi } from "../../Functions.js";
import ModalDelete from "../../components/ModalDelete";
import LocationAlert from '../../components/LocationAlert';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Permissions = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [roles, setRoles] = useState([]);
    const [lastid, setLastid] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const getAllRoles = async () => {
        const data = await fetchApi("mba", "/mba/modules/roles/get", "GET");
        const rolesArray = [];

        setLastid(data[data.length - 1].id);
        
        await Promise.all(data.map(role => {
            if(role.deleted === 0){
                rolesArray.push(role);
            }
        }))

        setRoles(rolesArray);
        initDataTable(); 
    }

    const addRole = async (newid, event) => {
        event.preventDefault();
        var rolename = event.target.rolename.value;

        if(rolename){
            const data = await fetchApi("mba", "/mba/modules/roles/insert", "GET", {
                "rolename": rolename, "newid": newid, "ismanager": event.target.manager.checked, "isadmin": event.target.admin.checked, "hasmba": event.target.mba.checked
            });

            if(data && data.length > 0){
                navigate('/manage/permissions/role/' + data[0].id);
            }
        }
    }

    const delRole = async (roleid, rolename) => {
        if(roleid){
            const data = await fetchApi("mba", `/mba/modules/roles/get/${roleid}/delete`, "GET", {
                "roleid": roleid, "rolename": rolename
            });

            await getAllRoles();
            crudAlertControl('delete', data);
            setShowModal(false);
        }
    }

    useEffect(() => {
        getAllRoles();
    }, [])

    const newid = (lastid + 1);

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <div onClick={() => setShowModal(true)} className="btn btn-add">{t('add')}</div>
                <h1>{t('permissions')}</h1>

                <div className="alert alert-success alert-add" role="alert">
                    {t('newrole')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('delrole')}
                </div>

                <div className="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>


                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('name')}</th>
                                <th>{t('manager')}</th>
                                <th>{t('managerights')}</th>
                                <th>MBA</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map((role, i) => {
                                var adminrights = role.isadmin === 1 ? <i class="check fas fa-check"></i> : <i class="no-check fas fa-times"></i>;
                                var manager = role.ismanager === 1 ? <i class="check fas fa-check"></i> : <i class="no-check fas fa-times"></i>;
                                var mba = role.hasmba === 1 ? <i class="check fas fa-check"></i> : <i class="no-check fas fa-times"></i>;

                                return (
                                    <tr>
                                        <td onClick={() => navigate(`/manage/permissions/role/${role.id}`)}>{t(`${role.slug ? role.slug : role.name}`)}</td>
                                        <td>{manager}</td>
                                        <td>{adminrights}</td>
                                        <td>{mba}</td>
                                        <td><i onClick={() => confirmDelete(t('delitem'), function () { delRole(role.id, role.name) })} style={{ float: 'right', color: '#ffffff', marginTop: '8px', marginRight: '10px', fontSize: '13px', cursor: 'pointer' }} className="btn-delete fas fa-trash-alt"></i></td>
                                    </tr>);
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>

                <ModalDelete />

                {showModal && (<div style={{ display: 'block' }} id="add" className="modal fade in">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('addrole')}</h4>
                            <div onClick={() => setShowModal(false)} className="close">&times;</div>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={(event) => addRole(newid, event)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="rolename">{t('rolename')}</label>
                                            <input type="text" name="rolename" id="rolename" className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="rolename">{t('manager')}</label>
                                            <label className='switch' style={{display: 'block'}}>
                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} name="manager" id="manager" type='checkbox' />
                                            <span className='slider round'></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="rolename">{t('managerights')}</label>
                                            <label className='switch' style={{display: 'block'}}>
                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} name="admin" id="admin" type='checkbox' />
                                            <span className='slider round'></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="rolename">MBA</label>
                                            <label className='switch' style={{display: 'block'}}>
                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} name="mba" id="mba" type='checkbox' />
                                            <span className='slider round'></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" onClick={() => setShowModal(false)}>
                                        <button id="cancel" className="btn btn-red cancel">{t('cancel')}</button>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-green">{t('add')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> )}
            </div>
        </React.Fragment>
    );
}

export default withAuth(Permissions);